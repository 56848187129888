import React from 'react'
import { FaGithub, FaLinkedin } from "react-icons/fa";
import SideLink from './SideLink';
import ContactDetails from './ContactDetails';

const ContactInfo = () => {
  return (
    <>
      <h2 className='mt-2 text-zinc-200 text-center text-2xl font-bold'>
        Contact Me
      </h2>
      <ul>

        <ContactDetails method="PHONE" value="(708)-916-2693" />
        <ContactDetails method="EMAIL" value="javazq25@gmail.com" />
        <SideLink linkto="https://www.github.com/jvazquez34139" text="Github" icon={<FaGithub />} />
        <SideLink linkto="https://www.linkedin.com/in/jose-vazquez-349818240/" text="linkedin" icon={<FaLinkedin />} />

      </ul>
    </>
  )
}

export default ContactInfo;