// import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import SideInfo from './components/baseLayout/SideInfo';
import Projects from './components/projects/Projects';
import Navbar from './components/baseLayout/Navbar';
import Header from './components/baseLayout/Header';
import Contact from './components/contact/Contact';
import About from './components/about/About';

function App() {
  return (
    <Router>
      <div className="App relative max-w-7xl mx-auto">
        <div className="absolute top-0 flex flex-col flex-grow max-w-7xl ml-1">
          <Header />
          <Navbar />
          <main className="md:ml-52 p-10 text-neutral-300 md:min-h-[65vh] flex flex-col align-middle bg-zinc-800" >
            <Routes>
              <Route path="/" element={<Projects />} />
              {/* build route path to contact me */}
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="*" element={<h1>404: Page not found</h1>} />
            </Routes>
          </main>
          <SideInfo />
          <footer className="flex flex-col p-3 items-center bg-gradient-to-b from-neutral-700 to-neutral-900 md:ml-52 h-32">
            <p className="text-neutral-300">© 2022 Jose Vazquez</p>
            <p className="text-neutral-300">All Rights Reserved</p>
            <p className="text-neutral-300">Terms of Service</p>
          </footer>
        </div>
      </div>
    </Router >
  );
}

export default App;
