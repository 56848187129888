import React from 'react'
import bgimg from './motherboard.jpg';
const Header = () => {
  return (
    <header className=" relative md:ml-52 h-52 md:flex md:flex-row md:justify-between md:items-end">
      <img src={bgimg} alt="motherboard" className="absolute object-cover w-full h-full bg-zinc-900 opacity-20 -z-10" />
      <div>
        <h2 className="text-7xl text-zinc-200 ml-5 mb-3"> Jose Vazquez</h2>
        <h1 className="">
        </h1>
      </div>


    </header >
  )
}

export default Header;