import React from 'react'
import { Link } from 'react-router-dom'

const NavItem = (props) => {
  const { linkto, text } = props
  return (
    <Link to={linkto} className="font-bold text-lg text-sky-100 hover:text-teal-300 duration-200" >
      {text}
    </Link>
  )
}

export default NavItem;