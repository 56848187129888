import React from 'react'
import PropTypes from 'prop-types';

const ProjectItem = (props) => {
  const { project } = props;
  const { name, description, image, github, liveDemo } = project;

  return (
    <div className="hover:bg-sky-700 mb-5" >
      {/* top corners of image rounded */}

      <img alt="project 1" src={image} className="w-full h-64 rounded-t-lg"></img>
      {/* <a href="hello.js.com" target="_blank">Link to Project</a> */}
      <p className='mt-3'>{description}</p>
      <a href="" target="_blank" className="bg-cyan-900 px-2 rounded-b-lg w-full block text-center mt-2 text-lg">Live Demo</a>

    </div>
  )
}

ProjectItem.propTypes = {
  project: PropTypes.object.isRequired
}

export default ProjectItem;