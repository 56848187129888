import React from 'react'

const Tag = (props) => {
  const { name, type } = props;
  let cname = "text-sky-400"
  switch (type) {
    case "FE":
      cname = "text-sky-400"
      break;
    case "BE":
      cname = "text-orange-400"
      break;
    case "DB":
      cname = "text-green-400"
      break;
    case "Tools":
      cname = "text-purple-400"
      break;
    default:
      cname = "text-sky-400"
      break;
  }

  return (
    <p className={`bg-neutral-700 px-1 rounded-md md:text-sm ${cname}`}>{name}</p>
  )
}

export default Tag;