import React from 'react'

import NavItem from './NavItem'

const Navbar = () => {
  return (
    <nav className="flex justify-around items-center bg-gradient-to-b from-neutral-700 h-14 to-neutral-900 md:ml-52 md:invisible md:h-0">
      <NavItem linkto="/" text="Projects" />
      <NavItem linkto="/about" text="Bio" />
      <NavItem linkto="/contact" text="Contact" />
    </nav>
  )
}

export default Navbar;