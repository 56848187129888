import React from 'react'
import { Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

const Contact = () => {
  return (
    <>
      <Link to="/">
        <i className="invisible text-6xl text-neutral-100 px-1 bg-neutral-600 absolute md:visible">
          <BiArrowBack className="text-3xl text-neutral-100 " />
        </i>
      </Link>
      <div className="w-full md:px-14 flex flex-col">
        <div className="flex flex-col items-center">
          <h2 className="text-3xl font-bold text-neutral-100">Message Me</h2>
          <p className="text-xl text-neutral-300 leading-7 mt-5">I am currently looking for a full time position. If you have any questions or would like to get in touch, please send me a direct email here!</p>
        </div>
        <form className='mt-5 flex flex-col'>
          <textarea rows="6" placeholder="Tell me anything!" className="" />
          <button type="submit" class="w-full bg-neutral-700 rounded text-xl font-bold h-10 mt-4">Send!</button>
        </form>
      </div>
    </>
  )
}

export default Contact;
