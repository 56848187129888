import React from 'react'
import PropTypes from 'prop-types';

const ContactDetails = (props) => {
  const { method, value } = props;
  return (
    <li>
      <div className="font-bold text-center text-zinc-500 mt-2">{method}</div>
      <div className="text-center font">{value}</div>
    </li>
  )
}

ContactDetails.prototype = {
  method: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export default ContactDetails;
