import React from 'react'
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import ContactInfo from './ContactInfo';

const SideInfo = () => {
  return (
    <aside className="flex flex-col w-full text-center md:absolute  md:top-0 md:left-0 md:h-screen md:w-52 bg-zinc-900 p-2 text-zinc-400" >
      <img className="md:w-48 h-auto block" alt="me" src='https://imageio.forbes.com/specials-images/imageserve/5faad4255239c9448d6c7bcd/Best-Animal-Photos-Contest--Close-Up-Of-baby-monkey/960x0.jpg?format=jpg&width=960' />

      <div className='flex flex-col sm:flex-row sm:justify-evenly  sm:items-center md:flex-col'>
        <div className="sm:mx-auto">
          <h2 className="mt-2 text-zinc-100 text-2xl font-bold">
            About Me
          </h2>

          <p className="text-left sm:w-96 md:w-auto md:px-0">
            I am a full stack web developer with a handful of projects. I have a passion for learning and a desire to help others. I am a problem solver and a team player. I am looking for a company that will allow me to grow and learn new skills.
          </p>
        </div>


        <div className="flex flex-col sm:mx-auto">

          <ContactInfo />
        </div>
      </div>

      <Link to="/about">
        <h3 className="mt-7 flex w-full items-center justify-center text-2xl bg-zinc-700 p-2 text-zinc-200 hover:text-sky-300 rounded group">
          Learn More <BsArrowRight className="text-4xl text-zinc-200 group-hover:text-sky-300" />
        </h3>
      </Link>
    </aside >
  )
}

export default SideInfo;