import React from 'react'

const SideLink = (props) => {
  const { linkto, text, icon } = props
  return (

    <li className="align-middle flex mt-3">
      <div className="items-center justify-center leading-5 text-xl font-bold text-zinc-500 ml-auto">
        {icon}
      </div>
      <a href={linkto} target="_blank" rel="noreferrer" className=" font-bold text-zinc-500 uppercase items-center leading-5 text-xl ml-1 mr-auto" >{text}</a>

    </li>
  )
}



export default SideLink;